<template>
  <v-card ref="bg" class="rounded-lg pa-2">
    <div class="d-flex align-center">
      <v-skeleton-loader
        v-if="loading"
        type="avatar"
        :width="50"
        :height="50"
        class="mr-2"
      />
      <base-avatar
        v-else
        :src="organization?.photo"
        :seed="organization?.id"
        :size="50"
        avatarStyle="shapes"
        color="secondary lighten-2"
      />
      <div class="ml-2" style="cursor: pointer" @click="goToOrg">
        <small class="mb-0 lh-1">Organização do evento</small>
        <v-skeleton-loader
          v-if="loading"
          type="heading"
          :width="150"
          height="20"
        />
        <h5 class="mb-0 text-16">
          {{ organization?.name }}
        </h5>
      </div>
      <v-spacer />

      <div class="text-end" v-if="!loading">
        <v-tooltip top v-if="organization.phone && organization.ddi">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="openOrgWhatsapp">
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
          </template>
          <span>{{ organization.phone }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :href="`mailTo:${organization.email}`"
              icon
              target="_blank"
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </template>
          <span>{{ organization.email }}</span>
        </v-tooltip>
        <v-tooltip top v-for="(sn, i) in organization.socialNetworks" :key="i">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :href="socialNetworksItens[sn.type].urlFormatter(sn.url)"
              icon
              v-bind="attrs"
              v-on="on"
              target="_blank"
            >
              <span
                v-if="socialNetworksItens[sn.type].image"
                v-html="socialNetworksItens[sn.type].image"
                class="mr-1"
              ></span>
              <v-icon v-else>{{ socialNetworksItens[sn.type].icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ socialNetworksItens[sn.type].prefix }}{{ sn.url }}</span>
        </v-tooltip>
      </div>
      <!-- <v-btn :href="`https://wa.me/${organization.phone}`" icon target="_blank">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn> -->
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    socialNetworksItens: {
      ig: {
        id: "ig",
        name: "Instagram",
        icon: "mdi-instagram",
        urlFormatter: (url) => `https://instagram.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      fb: {
        id: "fb",
        name: "Facebook",
        icon: "mdi-facebook",
        urlFormatter: (url) => `https://facebook.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      tw: {
        id: "tw",
        name: "Twitter",
        icon: "mdi-twitter",
        urlFormatter: (url) => `https://twitter.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      yt: {
        id: "yt",
        name: "Youtube",
        icon: "mdi-youtube",
        urlFormatter: (url) => url,
        prefix: "",
        placeholder: "Digite o link do canal",
      },
      tt: {
        id: "tt",
        name: "TikTok",
        image: `<svg fill="currentColor" width="22px" height="22px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>`,
        urlFormatter: (url) => `https://tiktok.com/@${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
    },
  }),
  methods: {
    openOrgWhatsapp() {
      const phone = this.organization.phone.replace(/\D/g, "");
      const ddi = this.organization.ddi;
      window.open(`https://wa.me/${ddi}${phone}`);
    },
    goToOrg() {
      this.$router.push({
        name: "shop.organization",
        params: {
          orgSlug: this.organization.slug,
        },
      });
    },
    setBg() {
      // const el = this.$refs.bg.$el;
      // const color = this.$vuetify.theme.dark ? '#1e1e1e' : '#fff';
      // el.style.backgroundImage = `linear-gradient(to right, transparent 0%, ${color} 95%), url(${this.organization.cover})`
      // el.style.backgroundSize = '49%';
      // el.style.backgroundPositionY = 'center';
    },
  },
  watch: {
    "$vuetify.theme.dark"() {
      this.setBg();
    },
    "organization.cover"() {
      this.setBg();
    },
  },
  mounted() {
    this.setBg();
  },
  props: {
    organization: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
