<template>
  <v-dialog v-model="show" max-width="380">
    <v-card>
      <v-card-title class="headline flex-column">
        <v-icon large>mdi-shield-link-variant</v-icon>
        <span>Atenção</span>
      </v-card-title>
      <v-card-text class="text-center pb-1" style="text-wrap: balance">
        Você está prestes a ser redirecionado para um  site externo.
        <div class="my-5">
          <code class="text-13">{{ getDomain }}</code>
        </div>
        <p class="mb-0 font-weight-bold">Deseja continuar?</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="grey" text @click="cancel"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="confirm"> Continuar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  computed: {
    getDomain() {
      try {
        const url = new URL(this.link);
        return url.hostname;
      } catch (error) {
        return this.link;
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
