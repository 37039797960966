<template>
  <div>
    <v-img
      :src="imageUrl"
      alt="Banner"
      :aspect-ratio="aspectRatio"
      class="rounded-lg"
      :style="{ cursor: link ? 'pointer' : 'default' }"
      @click="handleClick"
    />

    <warning-link-modal
      :show="showWarningModal"
      :link="link"
      @cancel="showWarningModal = false"
      @confirm="continueToExternalLink"
    />
  </div>
</template>

<script>
import WarningLinkModal from './WarningLinkModal.vue';

export default {
  components: {
    WarningLinkModal,
  },
  props: {
    aspectRatio: {
      type: Number,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
    isSafetyLink: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showWarningModal: false,
    };
  },
  methods: {
    handleClick() {
      if (this.link) {
        if (this.isSafetyLink) {
          window.open(this.link, "_blank");
        } else {
          this.showWarningModal = true;
        }
      }
    },
    continueToExternalLink() {
      this.showWarningModal = false;
      window.open(this.link, "_blank");
    },
  },
};
</script>
